import { useBucket } from '@context/bucket.context';
import Cookies from 'universal-cookie';
import { AppCookies } from '@enums/cookies';
import Environment from '@config/index';

export const useSiteConfirmation = () => {
  const { isFeatureEnabled } = useBucket();
  const ffValue = isFeatureEnabled((f) => !!f.showSiteConfirmationPopup, false) || false;
  const cookies = new Cookies();

  const hideLangPopup = () => {
    if (ffValue) {
      cookies.set(AppCookies.NV_SHOW_LANG_POPIN, false, {
        path: '/',
        domain: Environment.cookieOptions.domain,
      });
    }
  };

  return {
    hideLangPopup,
    siteConfirmationFfValue: ffValue,
    shouldHideLangPopup: cookies.get(AppCookies.NV_SHOW_LANG_POPIN) === false,
  };
};
