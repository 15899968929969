import Button from '@components/atoms/button/button';
import { AvailableCountryISOCodes, Country } from '@interfaces/models/country';
import { I18n, I18nLanguage, UserI18n } from '@interfaces/models/i18n';
import FormHoc, { FormChildrenProps, FormValues } from '@components/molecules/form-hoc/form-hoc';
import Environment from '@config/index';
import { usePreferences } from '@context/preferences.context';
import PreferenceCookie from '@services/preferences-cookie-service';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import UserService from '@services/user-service';
import { AvailableLocales } from '@interfaces/models/locale';
import { Currency } from '@interfaces/models/currency';
import logger from '@helpers/utils/logger/client';
import { getSubdomainByLocale, getLanguageByLocale } from '@helpers/localization';
import Cookies from 'universal-cookie';
import useUser from '@hooks/user/use-user';
import { RegionId } from '@interfaces/models/region';
import { AppCookies } from '@enums/cookies';
import { useBucket } from '@context/bucket.context';
import { NEW_LANGUAGE_FF_MAPPING } from '@config/localization';
import useLocalizationQuery from '@hooks/localization/use-localization';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { useSiteConfirmation } from '@hooks/use-site-confirmation';
import useDisplayedPreference from '@hooks/localization/use-displayed-preference';
import useGetI18N from '@hooks/localization/use-get-i18n';
import styles from './i18n.module.scss';

type I18nFormValues = {
  selectedLocale: AvailableLocales;
  selectedCountryISOCode: AvailableCountryISOCodes;
  selectedCurrency: Currency['isoCode'];
};

export enum I18nFormType {
  NEW_VISITOR = 'nv',
  FOOTER = 'footer',
}

export interface I18nFormProps {
  type: I18nFormType;
}

const I18nForm: React.FC<I18nFormProps> = ({ type }) => {
  const { localization: i18n } = useLocalizationQuery();
  const { t } = useTranslation('default-layout');
  const { isAuthenticated } = useUser();
  const isNvPopup = type === I18nFormType.NEW_VISITOR;
  const { currency, country, locale } = usePreferences();
  const i18nLanguage = i18n.language || {
    list: [],
    title: '',
  };
  const i18nCurrency = i18n.currency || {
    list: [],
    title: '',
  };
  const { isFeatureEnabled } = useBucket();
  const { hideLangPopup } = useSiteConfirmation();
  const { sendAnalyticEvent } = useAnalyticEvents('i18n_popup');

  const [cookies] = useState<Cookies>(() => new Cookies());
  const [selectedLocale, setSelectedLocale] = useState<AvailableLocales>(locale);
  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState<AvailableCountryISOCodes>(country);
  const countryInfo: Country = i18n.country.fullList.find(({ isoCode }) => isoCode === selectedCountryISOCode);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency['isoCode']>(currency);
  const selectedRegion = countryInfo?.idRegion;
  const { displayedCountry, displayedLang, displayedCurrency } = useDisplayedPreference();

  const refreshPage = (isoCode: AvailableLocales): void => {
    // On local env, because we only have 1 origin, replace doesn't work, so we have to manually reload for changes to take effect
    if (Environment.envName === 'dev') {
      window.location.reload();
      return;
    }
    const url = new URL(window.location.href);
    const newSubdomain = getSubdomainByLocale(isoCode, 'en-GB');
    const subdomains = url.host.split('.');
    subdomains[0] = newSubdomain;
    url.host = subdomains.join('.');
    window.location.replace(url.toString());
    if (window.location.hash) {
      window.location.reload();
    }
  };

  const onSubmit = async (): Promise<void> => {
    try {
      // Map user selected language locale to our supported language
      const language = getLanguageByLocale(selectedLocale);

      cookies.set(AppCookies.CK, `${countryInfo.idRegion}.${language}.${selectedCurrency}`, {
        path: '/',
        domain: Environment.cookieOptions.domain,
      });

      cookies.set(
        AppCookies.CC,
        PreferenceCookie.encode({
          CC: countryInfo.isoCode as AvailableCountryISOCodes,
          displayName: {},
        }),
        {
          path: '/',
          domain: Environment.cookieOptions.domain,
        },
      );

      hideLangPopup();

      if (isAuthenticated) {
        const userI18n = {
          country: countryInfo.isoCode,
          language: language,
          currency: selectedCurrency,
        } as UserI18n;

        await UserService.updateUserSettings(userI18n);
      }
      refreshPage(selectedLocale);
    } catch (e) {
      logger.info(e);
    }
  };

  const setDefaultLanguageIfUnavailable = (availableLanguages: I18nLanguage[], formData: I18nFormValues) => {
    const isCurrentLanguageAvailable = availableLanguages.map((lang) => lang.isoCode).includes(formData.selectedLocale);
    if (!isCurrentLanguageAvailable && availableLanguages.length > 0) {
      formData.selectedLocale = availableLanguages[0].isoCode;
    }
  };

  const { i18n: newI18n, isLoading: isLoadingNewI18n } = useGetI18N({
    siteId: Number(selectedRegion) as RegionId,
    locale,
  });
  const data = isLoadingNewI18n ? i18n : newI18n;

  const setDefaultCurrencyIfUnavailable = (formData: FormValues) => {
    const currentLanguageAvailable = data?.currency?.list.filter(
      (currency) => currency.isoCode === formData.selectedCurrency,
    );
    if (!currentLanguageAvailable.length && data?.currency?.list.length > 0) {
      setSelectedCurrency(data?.currency?.list[0].isoCode);
    }
  };

  return (
    <div
      className={clsx(
        'vc-modal__innerContainer',
        isNvPopup ? styles['i18nChoices__container--nv'] : styles.i18nChocies__container,
      )}
    >
      <span className={styles.i18nChoices__modalTitle}>
        {isNvPopup ? t('I18N_MODAL.NEW_VISITOR.TITLE') : t('I18N_MODAL.TITLE')}
      </span>
      {isNvPopup && (
        <p className={clsx('vc-text-m', styles.i18nChoices__modalSubtitle)}>{t('I18N_MODAL.NEW_VISITOR.SUBTITLE')}</p>
      )}
      <FormHoc
        initialValues={{
          selectedCountryISOCode,
          selectedCurrency,
          selectedLocale,
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }: FormChildrenProps<I18nFormValues>) => {
          setDefaultLanguageIfUnavailable(i18nLanguage.list, values);
          setDefaultCurrencyIfUnavailable(values);

          const isI18nValChanged =
            selectedLocale !== displayedLang.isoCode ||
            selectedCountryISOCode !== displayedCountry.isoCode ||
            selectedCurrency !== displayedCurrency.isoCode;

          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.i18nChoices__gridContainer}>
                <div className={clsx(styles.i18nChoices__modalCol, styles.i18n__modalCol)}>
                  <label className="vc__label">{data.country.title}</label>
                  <div className="vc__select">
                    <select
                      aria-label={t('I18N.MODAL.SELECT.COUNTRY')}
                      data-cy="select_country"
                      name="isoCode"
                      value={selectedCountryISOCode}
                      className={styles.i18nChoices__select}
                      onChange={(e) => {
                        setSelectedCountryISOCode(e.target.value as AvailableCountryISOCodes);
                      }}
                    >
                      {data.country.shortLists.map((country) => (
                        <option
                          value={country.isoCode}
                          key={country.isoCode}
                        >
                          {country.name}
                        </option>
                      ))}
                      <optgroup label="..." />
                      {data.country.fullList.map((country) => (
                        <option
                          value={country.isoCode}
                          key={country.isoCode}
                        >
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={clsx(styles.i18nChoices__modalCol, styles.i18n__modalCol)}>
                  <label className="vc__label">{i18nLanguage.title}</label>
                  <div className="vc__select">
                    <select
                      aria-label={t('I18N.MODAL.SELECT.LANGUAGE')}
                      data-cy="select_language"
                      name="language"
                      className={styles.i18nChoices__select}
                      value={selectedLocale}
                      onChange={(e) => {
                        setSelectedLocale(e.target.value as AvailableLocales);
                      }}
                    >
                      {(data?.language?.list as I18n['language']['list'])?.map((lang: I18nLanguage) => {
                        // Checking for new language enablment status
                        // New Language will show up in the language list when it is included in NEW_LANGUAGE_FF_MAPPING and ff enables this language
                        // Once the new languages are all enabled, this code block can be removed or refactored for futher use for other new languages
                        if (NEW_LANGUAGE_FF_MAPPING && NEW_LANGUAGE_FF_MAPPING[lang.isoCode]) {
                          // Once LD fails, all new languages will be hidden
                          const ffName = NEW_LANGUAGE_FF_MAPPING[lang.isoCode];
                          const isLanguageEnabled = isFeatureEnabled((f) => !!f[ffName], false) || false;

                          if (isLanguageEnabled) {
                            return (
                              <option
                                value={lang.isoCode}
                                key={lang.name}
                              >
                                {lang.name}
                              </option>
                            );
                          }

                          return null;
                        }

                        return (
                          <option
                            value={lang.isoCode}
                            key={lang.name}
                          >
                            {lang.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className={clsx(styles.i18nChoices__modalCol, styles.i18n__modalCol)}>
                  <label className="vc__label">{i18nCurrency.title}</label>
                  <div className="vc__select">
                    <select
                      aria-label={t('I18N.MODAL.SELECT.CURRENCY')}
                      data-cy="select_currency"
                      name="currency"
                      className={styles.i18nChoices__select}
                      value={selectedCurrency}
                      onChange={(e) => {
                        setSelectedCurrency(e.target.value as Currency['isoCode']);
                      }}
                    >
                      {data?.currency?.list?.map((currency) => (
                        <option
                          value={currency.isoCode}
                          key={currency.isoCode}
                        >
                          {currency.symbol} {currency.isoCode}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className={styles['col-12']}>
                <div className={styles.i18nChoices__modalAction}>
                  <Button
                    data-cy="submit_i18n"
                    type="submit"
                    variant="primary"
                    size="medium"
                    fullWidth
                    {...(!isNvPopup && {
                      notFullWidthQuery: 'md-up',
                    })}
                    onClick={() => {
                      if (isNvPopup) {
                        if (isI18nValChanged) {
                          sendAnalyticEvent('confirm_i18n_saveChange', {
                            property: JSON.stringify({
                              country: selectedCountryISOCode,
                              currency: selectedCurrency,
                              language: selectedLocale,
                            }),
                          });
                        } else {
                          sendAnalyticEvent('confirm_i18n_continue', {
                            property: JSON.stringify({
                              country: displayedCountry.isoCode,
                              currency: displayedCurrency.isoCode,
                              language: displayedLang.isoCode,
                            }),
                          });
                        }
                      }
                    }}
                  >
                    {isNvPopup
                      ? isI18nValChanged
                        ? t('I18N_MODAL.NEW_VISITOR.SAVE_CHANGE')
                        : t('I18N_MODAL.NEW_VISITOR.SAVE_BUTTON')
                      : t('I18N_MODAL.SAVE_BUTTON')}
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </FormHoc>
      {isNvPopup && (
        <p className={clsx('vc-text-s', styles['i18nChoices__modalHelpText--nv'])}>
          {t('I18N_MODAL.NEW_VISITOR.HELPER_TEXT')}
        </p>
      )}
    </div>
  );
};

export default I18nForm;
