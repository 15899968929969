import { useMemo, useCallback } from 'react';
import { I18nLanguage } from '@interfaces/models/i18n';
import { Currency } from '@interfaces/models/currency';
import { Country } from '@interfaces/models/country';
import { usePreferences } from '@context/preferences.context';
import { useBucket } from '@context/bucket.context';
import { MIGRATION_SITE_INFO } from '@config/localization';
import useGetI18N from './use-get-i18n';

const useLocalizationQuery = () => {
  const { siteId, locale } = usePreferences();
  const { isFeatureEnabled } = useBucket();
  const { i18n: localization } = useGetI18N({ siteId, locale });

  const updateSiteIdIfNecessary = useCallback(
    (countryList) => {
      return countryList.map((item) => {
        const regionCode = item.isoCode;
        const countryInfo = { ...item };

        if (MIGRATION_SITE_INFO[regionCode]) {
          const ffName = MIGRATION_SITE_INFO[regionCode].ffName;
          const ffValue = isFeatureEnabled((f) => !!f[ffName], false) || false;

          if (!ffValue) {
            countryInfo.idRegion = MIGRATION_SITE_INFO[regionCode].oldSiteId;
          }
        }

        return countryInfo;
      });
    },
    [isFeatureEnabled],
  );

  const locales: I18nLanguage[] = localization?.language?.list ?? [];
  const currencies: Currency[] = localization?.currency?.list ?? [];
  const fullList: Country[] = localization?.country?.fullList || [];
  const shortLists: Country[] = localization?.country?.shortLists || [];

  const countryList = useMemo(() => updateSiteIdIfNecessary(fullList), [fullList, updateSiteIdIfNecessary]);
  const countryShortList: Country[] = useMemo(
    () => updateSiteIdIfNecessary(shortLists),
    [shortLists, updateSiteIdIfNecessary],
  );

  return {
    // Localization should probably be removed, currently it's included to make sure i18n.tsx works,
    // that needs to be redone as well
    localization: {
      ...localization,
      country: {
        ...localization?.country,
        fullList: countryList,
        shortLists: countryShortList,
      },
    },
    locales,
    currencies,
    countryList,
    countryShortList,
  };
};

export default useLocalizationQuery;
