import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './button.module.scss';

export type ButtonVariant =
  | 'none'
  | 'primary'
  | 'primary-white'
  | 'secondary'
  | 'outline'
  | 'ghost'
  | 'social'
  | 'chip'
  | 'rounded-secondary';

type ButtonSize = 'none' | 'small' | 'medium' | 'large';

type ButtonNotFullWidthQuery = 'sm-down' | 'sm-up' | 'md-up' | 'lg-up';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  notFullWidthQuery?: ButtonNotFullWidthQuery;
  disableDefaultStyling?: boolean;
};

const Button: React.FC<ButtonProps> = (props) => {
  const {
    type = 'button',
    variant = 'none',
    size = 'none',
    fullWidth = false,
    notFullWidthQuery,
    disableDefaultStyling = false,
    children,
    className,
    ...buttonProps
  } = props;

  return (
    <button
      type={type}
      className={clsx(
        !disableDefaultStyling && 'vc-btn',
        variant !== 'none' && `vc-btn--${variant}`,
        size !== 'none' && `vc-btn--${size}`,
        fullWidth && `vc-btn--full`,
        fullWidth && notFullWidthQuery && styles[`button--notfull--${notFullWidthQuery}`],
        className,
      )}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export default Button;
