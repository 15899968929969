import { AuthenticationModalProps } from '@components/atoms/authentication/authentication-modal/authentication-modal';
import React, { FCWithChildren, useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import useUser from '@hooks/user/use-user';
import useLocalStorage from '@hooks/use-local-storage';
import { useSiteConfirmation } from '@hooks/use-site-confirmation';

type RedirectUrlMode = { url: string; mode: 'push' | 'replace' };

interface AuthContextProps {
  setAuthOpen: (popinForced: boolean) => void;
  // redirectUrl: where to go when authentication is *not* done, modal dismissed
  setRedirectUrl: (type: RedirectUrlMode) => void;
  redirectUrl: RedirectUrlMode;
  closeAuthPopin: () => void;
  setOnAuth: (func: Function) => void;
  onAuth: Function;
  shouldDisplayAuthDialog: boolean;
  setShouldDisplayAuthDialog: React.Dispatch<boolean>;
  isAuthOpen: boolean;
}

const AuthenticationContext = React.createContext<AuthContextProps>({} as never);

const AuthenticationModal: React.ComponentType<AuthenticationModalProps> = dynamic<AuthenticationModalProps>(
  () => import('@components/atoms/authentication/authentication-modal/authentication-modal'),
  {
    ssr: false,
  },
);

const AuthenticationProvider: FCWithChildren = ({ children }) => {
  const { isAuthenticated } = useUser();
  const { siteConfirmationFfValue, hideLangPopup } = useSiteConfirmation();

  const [shouldDisplayAuthDialog, setShouldDisplayAuthDialog] = useLocalStorage<boolean>(
    'shouldDisplayAuthDialog',
    true,
  );

  const [redirectUrl, setRedirectUrl] = useState<RedirectUrlMode>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // This state is used for tracking purposes
  const [popinForced, setPopinForced] = useState<boolean>(false);
  const [onAuth, setOnAuth] = useState<Function>(null);

  const setAuthOpen = (popinForced: boolean): void => {
    setIsOpen(true);
    setPopinForced(popinForced);
  };

  const closeAuthPopin = (): void => {
    setIsOpen(false);
  };

  // We've had issues with users being redirected before their information got back from the api, this is why we wait for this information.
  useEffect(() => {
    if (!isOpen && popinForced && !isAuthenticated && redirectUrl) {
      if (redirectUrl.mode === 'replace') {
        window.location.replace(redirectUrl.url);
      } else {
        window.location.href = redirectUrl.url;
      }
      setRedirectUrl(null);
    }
  }, [isOpen, popinForced, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && siteConfirmationFfValue) {
      hideLangPopup();
    }
  }, [isAuthenticated, siteConfirmationFfValue]);

  useEffect(() => {
    if (isAuthenticated && !isOpen) {
      onAuth?.();
    }
  }, [isAuthenticated, isOpen]);

  const value = {
    setAuthOpen,
    setRedirectUrl,
    redirectUrl,
    closeAuthPopin,
    setOnAuth,
    onAuth,
    shouldDisplayAuthDialog,
    setShouldDisplayAuthDialog,
    isAuthOpen: isOpen,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {/* TODO: this should work without isOpen here, but needs to be fixed inside VCAuth web component */}
      {isOpen && (
        <div
          className="wc-vc-authentication"
          id="vc-authentication"
        >
          <AuthenticationModal
            isOpen={isOpen}
            modalType="welcome-screen"
            popinForced={popinForced}
          />
        </div>
      )}
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = () => useContext(AuthenticationContext);

export { AuthenticationProvider, useAuthentication };
