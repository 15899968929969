import { useQuery } from '@tanstack/react-query';
import { LocalizationQueryKeys } from '@enums/react-query-keys';
import { I18n } from '@interfaces/models/i18n';
import { getI18n } from '@api/static';

const useGetI18N = ({
  siteId,
  locale,
}) => {
  const { data, isLoading } = useQuery<I18n>({
    queryKey: [LocalizationQueryKeys.LOCALIZATION, siteId, locale],
    queryFn: () => getI18n({ siteId, locale }),
    staleTime: Infinity,
  });

  return {
    i18n: data || {
      country: {
        fullList: [],
        shortLists: [],
        title: '',
      },
      currency: {
        list: [],
        title: '',
      },
      language: {
        list: [],
        title: '',
      }
    },
    isLoading,
  };
};

export default useGetI18N;
