import React, { useMemo, useState } from 'react';

export type FormValues = {
  [key: string]: string | number | string[];
};
type TouchedType = {
  [key: string]: boolean;
};
type ResetFormArgsType<T> = {
  values: T;
};

interface FormHocProps<T> {
  initialValues?: T;
  onSubmit: (val: T, opt: FormProps<T>) => void;
  children: ((props: FormChildrenProps<T>) => React.ReactNode) | React.ReactNode;
}

export interface FormProps<T> {
  setFieldValue?: (key: string, val: string) => void;
  setFieldTouched?: (key: string, isTouched?: boolean) => void;
  resetForm?: (val: ResetFormArgsType<T>) => void;
}

export interface FormChildrenProps<T> extends FormProps<T> {
  dirty?: boolean;
  touched?: TouchedType;
  values?: T;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const isEqual = (a: FormValues, b: FormValues) => {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  for (let i = 0; i < aKeys.length; i++) {
    if (!bKeys.includes(aKeys[i]) || a[aKeys[i]] !== b[aKeys[i]]) {
      return false;
    }
  }
  return true;
};

const FormHoc: React.FC<FormHocProps<FormValues>> = (props) => {
  const { initialValues = {}, onSubmit, children } = props;

  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});
  const dirty = useMemo(() => !isEqual(initialValues, values), [initialValues, values]);

  const setFieldValue = (key: string, val: string) => {
    setValues({ ...values, ...{ [key]: val } });
  };

  const setFieldTouched = (key: string, isTouched?: boolean) => {
    setTouched({
      ...touched,
      ...{ [key]: typeof isTouched === 'undefined' ? true : isTouched },
    });
  };

  const resetForm = (newVal: ResetFormArgsType<FormValues>) => {
    setValues(newVal.values);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e && e.preventDefault && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    if (e && e.stopPropagation && typeof e.stopPropagation === 'function') {
      e.stopPropagation();
    }
    onSubmit(values, {
      setFieldTouched,
      setFieldValue,
      resetForm,
    });
  };

  return typeof children === 'function'
    ? children({
        dirty,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        resetForm,
      })
    : React.Children.only(children);
};

export default FormHoc;
