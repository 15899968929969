import React, { useState, useEffect } from 'react';
import I18nForm, { I18nFormType } from '@components/organisms/footer/components/i18n';
import { useContextSelector } from 'use-context-selector';
import { ModalContext } from '@context/modal.context';
import useWindowSize from '@hooks/use-window-size';
import Button from '@components/atoms/button/button';
import useDisplayedPreference from '@hooks/localization/use-displayed-preference';
import { usePreferences } from '@context/preferences.context';
import { useTranslation } from 'next-i18next';
import useUser from '@hooks/user/use-user';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { useSiteConfirmation } from '@hooks/use-site-confirmation';
import Environment from '@config/environment';
import styles from './site-confirmation-banner.module.scss';

export const SiteConfirmationBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isShowI18n, setIsShowI18n] = useState(false);
  const openModal = useContextSelector(ModalContext, (v) => v.openModal);
  const { isWiderThanMd } = useWindowSize();
  const { displayedLang, displayedCurrency, displayedCountry } = useDisplayedPreference();
  const { language } = usePreferences();
  const { t } = useTranslation('default-layout');
  const { isAuthenticated } = useUser();
  const { sendAnalyticEvent } = useAnalyticEvents('i18n_popup');
  const { hideLangPopup, siteConfirmationFfValue, shouldHideLangPopup } = useSiteConfirmation();

  const closeBanner = () => {
    hideLangPopup();
    setIsVisible(false);
    sendAnalyticEvent('confirm_site_continue');
  };

  const openI18nModal = () => {
    sendAnalyticEvent('confirm_site_change');
    openModal(
      <div style={{ width: '100%' }}>
        <I18nForm type={I18nFormType.NEW_VISITOR} />
      </div>,
      {
        styles: {
          content: {
            ...(isWiderThanMd ? { width: '500px' } : { minWidth: '80%' }),
            minHeight: 'auto',
            display: 'inherit',
          },
        },
        onAfterClose: () => {
          hideLangPopup();
        },
      },
    );
    setIsVisible(false);
    setIsShowI18n(true);
  };

  useEffect(() => {
    if (isAuthenticated || !siteConfirmationFfValue || isShowI18n) {
      return;
    }

    if (!shouldHideLangPopup) {
      const browserAcceptedLang = navigator.languages;
      const acceptedLangs = browserAcceptedLang.map((lang) => lang.split('-')[0]);
      // exception is hk and us (zh-HK, en-US)
      // For other language codes, we're using the first two characters, example: fr-FR -> fr
      const currentLangMapping = {
        us: 'en',
        hk: 'zh',
      };

      if (
        !browserAcceptedLang ||
        !browserAcceptedLang.length ||
        !acceptedLangs.includes(currentLangMapping[language] || language)
      ) {
        setIsVisible(true);
      } else {
        hideLangPopup();
      }
    }
  }, [siteConfirmationFfValue]);

  return (
    isVisible && (
      <div className={styles['stripe-banner__container']}>
        <div className={styles['stripe-banner__content']}>
          <h1 className={styles['stripe-banner__title']}>
            <img
              className={styles['stripe-banner__title-flag']}
              src={`${Environment.apiStaticBaseUrl}/flags/${displayedCountry.isoCode.toLowerCase()}.svg`}
              alt={displayedCountry.name}
            />
            {t('SITE_CONFIRMATION.BANNER.TITLE', { country: displayedCountry.name })}
          </h1>
          <p className={styles['stripe-banner__info']}>
            {t('SITE_CONFIRMATION.BANNER.INFO', {
              country: displayedCountry.name,
              currencyISO: displayedCurrency.isoCode,
              currencySymbol: displayedCurrency.symbol,
              language: displayedLang.name,
            })}
          </p>
          <Button
            className={styles['stripe-banner__closeButton']}
            onClick={closeBanner}
          >
            {t('SITE_CONFIRMATION.BANNER.CONTINUE')}
          </Button>
          <Button
            className={styles['stripe-banner__changeButton']}
            onClick={openI18nModal}
          >
            {t('SITE_CONFIRMATION.BANNER.CHANGE')}
          </Button>
        </div>
      </div>
    )
  );
};
